<template>
    <el-container>
        <!-- 头部 -->
        <el-header class="ns-login-header">
            <div class="header-in">
                <!-- <ns-header-mid /> -->
                <el-row>
                    <el-col :span="2">
                        <router-link to="/" class="logo-wrap"><img :src="$img(shopInfo.logo)" /></router-link>
                    </el-col>

                    <p style="margin-left: 150px;">{{ login_register }}</p>
                </el-row>
            </div>
        </el-header>
        <el-main>
            <transition name="slide"><router-view /></transition>
        </el-main>
        <!-- 底部 -->
        <el-footer class="login-footer">
            <!-- <NsFooter></NsFooter> -->
            <!-- <copy-right :shopName="siteInfo.title"></copy-right> -->
        </el-footer>
        <NsFooter></NsFooter>
    </el-container>
</template>
<script>
import CopyRight from "./components/CopyRight"
import NsHeaderMid from "./components/NsHeaderMid"
import NsFooter from "./components/NsFooter"
import { shopInfo } from '@/api/shop/index';
import { mapGetters } from "vuex"

export default {
    data: () => {
        return {
            login_register: 'Welcome',
            shopInfo: ""
        }
    },
    name: "Layout",
    components: {
        CopyRight,
        NsHeaderMid,
        NsFooter
    },
    created() {
        this.login_register = this.$route.path == "/login" ? 'Welcome' : '欢迎注册';
        this.$store.dispatch("site/siteInfo")
        this.getShopInfo();
    },
    watch: {
        $route(to, from) {
            this.login_register = to.path == "/login" ? 'Welcome' : '欢迎注册';
        }
    },
    computed: {
        ...mapGetters(["siteInfo"])
    },
    methods: {
        getShopInfo() {
            shopInfo({ site_id: 4 }).then(res => {
                console.log(res);
                if (res.code >= 0) {
                    this.shopInfo = res.data;
                }
            })
        }
    }
}
</script>
<style lang="scss">
.ns-login-header {
    height: 89px !important;
}

.header-in {
    width: $width;
    height: 89px;
    margin: 20px auto 0;

    .logo-wrap {
        width: 240px;
        height: 68px;
        display: block;
        line-height: 68px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    p {
        font-size: 28px;
    }
}

.login-footer .footer-bottom p .footer-link,
.login-footer .footer-bottom p .el-link.el-link--default {
    color: #303133;
}

.login-footer .footer-bottom p .footer-link:hover {
    color: $base-color;
}
</style>
